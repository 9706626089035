import React, { useState } from 'react';

import '../icons';

import '../styles/style.scss';
import '../styles/typography.scss';
import Header from './Header';
import Footer from './Footer';
import CookieBar from './CookieBar';

import * as styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  transparentHeader: boolean;
}

const Layout = ({ children, className, transparentHeader }: LayoutProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.wrapper + (transparentHeader ? ' ' + styles.withTransparentHeader : '')}>
      <Header
        transparent={transparentHeader}
        isMenuOpen={isMenuOpen}
        onHamburguerMenuTouch={() => setIsMenuOpen(!isMenuOpen)}
      ></Header>
      <div
        id="main"
        className={styles.main + (className ? ' ' + className : '')}
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        {children}
      </div>
      <Footer
        onClick={() => {
          setIsMenuOpen(false);
        }}
      ></Footer>
      <CookieBar></CookieBar>
    </div>
  );
};

export default Layout;
