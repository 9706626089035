import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { getMenuLinkClasses } from '../utils/utils';
import { LocationContext } from '../contexts/LocationContext';

import * as styles from './DropDownMenu.module.scss';
import LocalizedLink from './LocalizedLink';

export interface SingleEntry {
  url: string;
  title: string;
  sections?: Array<{
    name: string;
    nameEn: string;
  }>;
  hideSectionsMenu?: boolean | null;
}

export interface SubmenuEntry {
  title: string;
  entries: Array<SingleEntry>;
}

interface DropDownMenuProps {
  entries: Array<SingleEntry | SubmenuEntry>;
}

const DropDownMenu = ({ entries }: DropDownMenuProps): React.ReactElement => {
  const location = useContext(LocationContext);

  return (
    <div className={styles.dropMenuContainer}>
      {entries.map((entry, i) => (
        <div key={i} className={styles.sectionLinkContainer}>
          {'entries' in entry ? (
            <div className={styles.subEntriesContainer}>
              <span
                className={
                  styles.sectionLink +
                  (location &&
                  entry.entries.some(subEntry => location.pathname.startsWith(subEntry.url))
                    ? ' ' + styles.selected
                    : '')
                }
              >
                {entry.title}
              </span>
              {entry.entries.map((subEntry, i) => (
                <LocalizedLink
                  key={i}
                  to={subEntry.url}
                  className={
                    styles.subEntryLink +
                    (location &&
                      getMenuLinkClasses(
                        location.pathname,
                        subEntry.url,
                        styles.selected,
                        styles.disabled,
                      ))
                  }
                >
                  {subEntry.title}
                </LocalizedLink>
              ))}
            </div>
          ) : (
            <LocalizedLink
              to={entry.url}
              className={
                styles.sectionLink +
                (location
                  ? getMenuLinkClasses(
                      location.pathname,
                      entry.url,
                      styles.selected,
                      styles.disabled,
                    )
                  : '')
              }
            >
              {entry.title}
            </LocalizedLink>
          )}
        </div>
      ))}
    </div>
  );
};

export default DropDownMenu;
