// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var dropMenuContainer = "CountriesWebsitesDropDown-module--dropMenuContainer--dPo94";
export var error = "#f66a83";
export var externalLinkIcon = "CountriesWebsitesDropDown-module--externalLinkIcon--EagaW";
export var formControl = "CountriesWebsitesDropDown-module--formControl--J+BWF";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "CountriesWebsitesDropDown-module--icon--l-IXL";
export var iconLeft = "CountriesWebsitesDropDown-module--iconLeft--VwiPd";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var menuItem = "CountriesWebsitesDropDown-module--menuItem--qwkGF";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var paper = "CountriesWebsitesDropDown-module--paper--bwP3r";
export var placeholder = "CountriesWebsitesDropDown-module--placeholder--zhfxe";
export var root = "CountriesWebsitesDropDown-module--root--nMFxw";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";