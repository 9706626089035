import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import GenericLink from './GenericLink';
import { getCookieValue, setCookie } from '../utils/cookies';

import * as styles from './CookieBar.module.scss';
import { useLocalization } from '../utils/hooks';
import { Trans } from 'react-i18next';

const COOKIE_NAME = 'CookieConsent';

interface LocalizedQueryData {
  sanityWebsite: {
    cookiePolicyReference: {
      slug: {
        current: string;
      };
    };
  };
}

const CookieBar = (): React.ReactElement => {
  const location = useLocation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (getCookieValue('CookieConsent') === undefined) {
      setHidden(false);
    }
  }, []);

  const data = useStaticQuery(graphql`
    {
      sanityWebsite {
        cookiePolicyReference {
          slug {
            current
          }
        }
      }
    }
  `);
  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  const { cookiePolicyReference } = localizedData.sanityWebsite;

  return (
    <div className={styles.container + (hidden ? ' ' + styles.hidden : '') + ' mui-fixed'}>
      <div className={styles.innerContainer}>
        <div className={styles.content}>
          <p>
            {t(
              'cookie_bar.text1',
              'We use cookies to offer you a better experience, analyze site traffic, and serve targeted advertisements.',
            )}
          </p>
          <p>
            <Trans
              i18nKey="cookie_bar.text2"
              defaults="By clicking Accept you consent to the use of cookies in accordance with our <policyLink>Cookie Policy</policyLink>."
              components={{
                policyLink: cookiePolicyReference ? (
                  <GenericLink
                    className={styles.cookiePolicyLink}
                    key="link"
                    to={'/' + cookiePolicyReference.slug.current}
                  >
                    _
                  </GenericLink>
                ) : (
                  <span key="link">_</span>
                ),
              }}
            />
          </p>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.button + ' ' + styles.declineButton}
            onClick={() => {
              setCookie(COOKIE_NAME, 'false', { expires: 3650, sameSite: 'strict' });
              setHidden(true);
            }}
          >
            {t('cookie_bar.decline_button', 'Decline')}
          </button>
          <button
            className={styles.button}
            onClick={() => {
              setCookie(COOKIE_NAME, 'true', { expires: 3650, sameSite: 'strict' });
              setHidden(true);
              initializeAndTrack(location);
            }}
          >
            {t('cookie_bar.accept_button', 'Accept')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBar;
