import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as styles from './Footer.module.scss';

import RovensaLogoSvg from '../assets/images/logos/ROVENSA_white_well-balanced-agriculture.svg';

import CountriesWebsitesDropDownFooter from './CountriesWebsitesDropDownFooter';
import { useLocalization } from '../utils/hooks';
import LocalizedLink from './LocalizedLink';
import { MenuItem, SubmenuItem } from '../graphql-fragments/Menu';

interface FooterProps {
  onClick: () => void;
}

interface LocalizedQueryData {
  sanityWebsite: {
    footerNavigationMenuReference: {
      title: string;
      items: Array<MenuItem | SubmenuItem>;
    };
    footerSocialMedia: {
      youtubeUrl: string;
      facebookUrl: string;
      twitterUrl: string;
      linkedInUrl: string;
      instagramUrl: string;
    };
    legalDocumentationNavigationMenuReference: {
      title: string;
      items: Array<MenuItem | SubmenuItem>;
    };
    partners: Array<{
      text: string;
      page: {
        title: string;
        slug: {
          current: string;
        };
      };
      image: {
        asset: {
          url: string;
        };
      };
    }>;
  };
}

const Footer = ({ onClick }: FooterProps): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          footerNavigationMenuReference {
            title
            items {
              ...Menu
            }
          }
          footerSocialMedia {
            youtubeUrl
            facebookUrl
            twitterUrl
            linkedInUrl
            instagramUrl
          }
          legalDocumentationNavigationMenuReference {
            title
            items {
              ...Menu
            }
          }
          partners {
            text
            page {
              title {
                ...LocaleStringFragment
              }
              slug {
                current
              }
            }
            image {
              asset {
                url
              }
            }
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const footerMenu = localizedData.sanityWebsite.footerNavigationMenuReference || {};
  const legalDocumentationMenu =
    localizedData.sanityWebsite.legalDocumentationNavigationMenuReference || {};
  const socialMedia = localizedData.sanityWebsite.footerSocialMedia || {};
  const partners = localizedData.sanityWebsite.partners || {};

  return (
    <>
      <footer className={styles.footer} onClick={onClick}>
        <div className={styles.container}>
          <div className={styles.topSection}>
            {footerMenu.items.length > 0 && (
              <section className={styles.section}>
                <h4 className={styles.title}>
                  {t('footer.navigation_menu_title', 'ASCENZA Global')}
                </h4>
                <div className={styles.menu}>
                  {footerMenu.items.map(
                    item =>
                      item.__typename === 'SanityMenuItems' && (
                        <LocalizedLink
                          key={item.page.slug.current}
                          to={`/${item.page.slug.current}`}
                          className={styles.link + ' footerLink'}
                        >
                          {item.title || item.page.title}
                        </LocalizedLink>
                      ),
                  )}
                </div>
              </section>
            )}
            <section className={styles.section + ' ' + styles.countryAndLogoSection}>
              <div className={styles.worldwide}>
                <span className={styles.subtitle + ' titleParagraph'}>
                  {t('footer.country_selector_title', 'ASCENZA Worldwide')}
                </span>
                <CountriesWebsitesDropDownFooter></CountriesWebsitesDropDownFooter>
              </div>
              <div className={styles.logoContainer}>
                <a
                  href="https://www.rovensa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.logoFooter}
                >
                  <img className={styles.logo} src={RovensaLogoSvg} alt="Rovensa Logo" />
                </a>
              </div>
            </section>

            {legalDocumentationMenu.items.length > 0 && (
              <section className={styles.section}>
                <h4 className={styles.title}>{t('footer.legal_docs_menu_title', 'Legal docs')}</h4>
                <div className={styles.legalDocumentation}>
                  {legalDocumentationMenu.items.map(
                    item =>
                      item.__typename === 'SanityMenuItems' && (
                        <LocalizedLink
                          key={item.page.slug.current}
                          to={`/${item.page.slug.current}`}
                          className={styles.link + ' footerLink'}
                        >
                          {item.title || item.page.title}
                        </LocalizedLink>
                      ),
                  )}
                </div>
                <span className={styles.copyright + ' titleParagraph'}>
                  &copy; ASCENZA Agro S.A. 2021
                </span>
              </section>
            )}
          </div>

          {!!socialMedia &&
            (!!socialMedia.youtubeUrl ||
              !!socialMedia.twitterUrl ||
              !!socialMedia.linkedInUrl ||
              !!socialMedia.facebookUrl ||
              !!socialMedia.instagramUrl) && (
              <section className={styles.socialMediaSection}>
                {!!socialMedia.youtubeUrl && (
                  <a
                    className={styles.socialMediaLink}
                    href={socialMedia.youtubeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'youtube']} className={styles.icon} />
                  </a>
                )}
                {!!socialMedia.twitterUrl && (
                  <a
                    className={styles.socialMediaLink}
                    href={socialMedia.twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} className={styles.icon} />
                  </a>
                )}
                {!!socialMedia.linkedInUrl && (
                  <a
                    className={styles.socialMediaLink}
                    href={socialMedia.linkedInUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} className={styles.icon} />
                  </a>
                )}
                {!!socialMedia.facebookUrl && (
                  <a
                    className={styles.socialMediaLink}
                    href={socialMedia.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} className={styles.icon} />
                  </a>
                )}
                {!!socialMedia.instagramUrl && (
                  <a
                    className={styles.socialMediaLink}
                    href={socialMedia.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} className={styles.icon} />
                  </a>
                )}
              </section>
            )}
        </div>
      </footer>

      <div className={styles.partners}>
        {partners.map((partner, index) => {
          return (
            <LocalizedLink
              key={index}
              className={styles.partnerImageLink}
              to={'/' + partner.page.slug.current}
            >
              <img
                className={styles.partnerImage}
                src={partner.image.asset.url}
                title={partner.text}
              />
            </LocalizedLink>
          );
        })}
      </div>

      <div className={styles.moscaSection}>
        <span>
          Powered by{' '}
          <a
            className={styles.moscaLink}
            href="https://moscadigital.pt/en/?utm_source=ascenza.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mosca Digital
          </a>
        </span>
      </div>
    </>
  );
};

export default Footer;
