import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import * as styles from './LanguageSelector.module.scss';

import { ALL_LANGS, DEFAULT_LANG } from '../constants';
import { useTranslation } from 'react-i18next';

import enFlag from '../assets/images/flags/en.png';
import ptFlag from '../assets/images/flags/pt.png';
import esFlag from '../assets/images/flags/es.png';
import frFlag from '../assets/images/flags/fr.png';
import itFlag from '../assets/images/flags/it.png';
import roFlag from '../assets/images/flags/ro.png';
import { getLocalizedUrlPath } from '../utils/i18n';

const LANG_TO_FLAG_IMG = {
  en: enFlag,
  pt: ptFlag,
  es: esFlag,
  fr: frFlag,
  it: itFlag,
  ro: roFlag,
};

export const LANG_TO_LANG_NAME = {
  en: 'English',
  pt: 'Português',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  ro: 'Română',
};

interface LanguageSelectorProps {
  className?: string;
}

const LanguageSelector = ({ className }: LanguageSelectorProps): React.ReactElement => {
  const location = useLocation();
  const { i18n } = useTranslation();

  let pathWithoutCountry = location ? location.pathname : '';
  if (
    ALL_LANGS.filter(lang => lang !== DEFAULT_LANG).filter(lang =>
      pathWithoutCountry.match(new RegExp(`^/${lang}($|/)`)),
    ).length > 0
  ) {
    pathWithoutCountry = pathWithoutCountry.substr(3) || '/';
  }

  const currentLanguage = i18n.language;
  return (
    <div className={className ? ' ' + className : ''}>
      <FormControl className={styles.formControl}>
        <Select
          value={currentLanguage}
          onChange={(event: any) =>
            navigate(getLocalizedUrlPath(pathWithoutCountry, event.target.value))
          }
          displayEmpty
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            getContentAnchorEl: null,
            classes: { paper: styles.paper },
          }}
          classes={{ icon: styles.icon }}
          className={styles.selectEmpty + ' ' + styles.root}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {ALL_LANGS.map((lang, i) => (
            <MenuItem
              key={i}
              value={lang}
              className={styles.menuItem + (currentLanguage === lang ? ' ' + styles.active : '')}
            >
              <div className={styles.flag}>
                <img src={LANG_TO_FLAG_IMG[lang]} alt="" />
              </div>
              <span className={styles.name}>{LANG_TO_LANG_NAME[lang]}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelector;
