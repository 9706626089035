// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var arrow = "MenuEntry-module--arrow--UQGWf";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var darkSubmenu = "MenuEntry-module--darkSubmenu--CFGJc";
export var disabled = "MenuEntry-module--disabled--QsWPH";
export var error = "#f66a83";
export var footerLink = "MenuEntry-module--footerLink--m1Ag6";
export var green = "#3dae2b";
export var grey = "#828382";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var lightIcon = "MenuEntry-module--lightIcon--pez0U";
export var lightLink = "MenuEntry-module--lightLink--SDrrr";
export var lightSubmenuEntry = "MenuEntry-module--lightSubmenuEntry--p3ZoY";
export var link = "MenuEntry-module--link--ZkIFh";
export var linkSpacer = "MenuEntry-module--linkSpacer--3ny6D";
export var linkWrapper = "MenuEntry-module--linkWrapper--Z-oek";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var sectionsMenuContainer = "MenuEntry-module--sectionsMenuContainer--kiE0a";
export var sectionsMenuEntriesWrapper = "MenuEntry-module--sectionsMenuEntriesWrapper--EJGc9";
export var selected = "MenuEntry-module--selected--74khq";
export var subMenu = "MenuEntry-module--subMenu--MwrDr";
export var subMenuLink = "MenuEntry-module--subMenuLink--nG+B5";
export var submenuEntry = "MenuEntry-module--submenuEntry--bqjkF";
export var submenuIcon = "MenuEntry-module--submenuIcon--xBxJb";
export var submenuLinkSpacer = "MenuEntry-module--submenuLinkSpacer--fB9cD";
export var submenuTitle = "MenuEntry-module--submenuTitle--9G-1R";
export var success = "#1ebc7b";
export var titleParagraph = "MenuEntry-module--titleParagraph--u5I8h";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";