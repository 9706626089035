import React from 'react';
import { useTranslation } from 'react-i18next';
import { ALL_LANGS, DEFAULT_LANG } from '../constants';

interface WithI18nComponentProps {
  // we have two ways of passing language to make it easy to pass it
  // as a normal prop or from inside context in gatsby-node
  lang?: string;
  pageContext?: {
    lang?: string;
  };
}

export function withI18n(
  WrappedComponent: React.ComponentType<any>,
  defaultLang: typeof ALL_LANGS[number] = DEFAULT_LANG,
): React.ComponentType<WithI18nComponentProps> {
  const WithI18n = ({
    lang,
    pageContext,
    ...restProps
  }: WithI18nComponentProps): React.ReactElement => {
    const { i18n } = useTranslation();
    const wantedLang =
      lang ||
      pageContext?.lang ||
      (typeof window !== 'undefined' &&
        window.location &&
        ALL_LANGS.find(
          l =>
            window.location.pathname === '/' + l ||
            window.location.pathname.startsWith('/' + l + '/'),
        )) ||
      defaultLang;
    if (i18n.language !== wantedLang) {
      i18n.changeLanguage(wantedLang);
    }
    return <WrappedComponent pageContext={pageContext} {...restProps} />;
  };
  WithI18n.displayName = WrappedComponent.displayName;
  return WithI18n;
}
