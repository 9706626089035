import React from 'react';
import { FaGlobeAmericas, FaExternalLinkAlt } from 'react-icons/fa';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { graphql, Link, useStaticQuery } from 'gatsby';

import * as styles from './CountriesWebsitesDropDownFooter.module.scss';
import { useLocalization } from '../utils/hooks';

interface LocalizedQueryData {
  sanityWebsite: {
    countriesWebsites: Array<{
      country: string;
      website: string;
    }>;
  };
}

const CountriesWebsitesDropDownFooter = (): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          countriesWebsites {
            country {
              ...LocaleStringFragment
            }
            website
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const sanityWebsite = localizedData.sanityWebsite || {};

  const entries = sanityWebsite.countriesWebsites.map(countryWebsite => {
    return {
      country: countryWebsite.country,
      website: countryWebsite.website,
    };
  });

  function onChange(event: any) {
    window.open(event.target.value);
  }

  return (
    <div className={styles.dropMenuContainer}>
      <FormControl className={styles.formControl}>
        <Select
          value="Select country"
          onChange={onChange}
          displayEmpty
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            getContentAnchorEl: null,
            classes: { paper: styles.paper },
          }}
          classes={{ icon: styles.icon }}
          className={styles.selectEmpty + ' ' + styles.root}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="Select country" disabled className={styles.placeholder}>
            {t('footer.countries_websites_dropdown.placeholder', 'Select country')}
          </MenuItem>
          {entries.map((entry, i) => (
            <MenuItem key={i} value={entry.website} className={styles.menuItem}>
              <FaExternalLinkAlt className={styles.externalLinkIcon}></FaExternalLinkAlt>
              {entry.country}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CountriesWebsitesDropDownFooter;
