import React from 'react';
import * as styles from './MenuButton.module.scss';

interface MenuButtonProps {
  label: string;
  isMenuOpen: boolean;
  onTouch: () => void;
}

const MenuButton = ({ label, isMenuOpen, onTouch }: MenuButtonProps): React.ReactElement => {
  return (
    <nav className={styles.menuWrap + (isMenuOpen ? ' ' + styles.menuOpen : '')} onClick={onTouch}>
      <div className={styles.icon}>
        <div className={styles.line}></div>
      </div>
      <span className={styles.label}>{label}</span>
    </nav>
  );
};

export default MenuButton;
