// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "Header-module--container--yBjyO";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var dropDownMenuWrapper = "Header-module--dropDownMenuWrapper--WjWix";
export var dropDownMenuWrapperWindow = "Header-module--dropDownMenuWrapperWindow--okLDE";
export var error = "#f66a83";
export var externalLink = "Header-module--externalLink--Lave-";
export var green = "#3dae2b";
export var grey = "#828382";
export var hamburgerMenu = "Header-module--hamburgerMenu--Z1efm";
export var header = "Header-module--header--3ERvK";
export var headerSelector = "Header-module--headerSelector--AmcKk";
export var icon = "Header-module--icon--HokS6";
export var leftContainer = "Header-module--leftContainer--lToj+";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var logo = "Header-module--logo--m5asN";
export var logoContainer = "Header-module--logoContainer--RXvdS";
export var logosWrapper = "Header-module--logosWrapper--oUZe0";
export var logosWrapperWithoutLanguageSelector = "Header-module--logosWrapperWithoutLanguageSelector--TCljn";
export var menuOpen = "Header-module--menuOpen--+-gC-";
export var menuWrapper = "Header-module--menuWrapper--ric7o";
export var navBar = "Header-module--navBar--p-Uy-";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var shadow = "Header-module--shadow--r4o--";
export var success = "#1ebc7b";
export var tabletLogoContainer = "Header-module--tabletLogoContainer--izhdR";
export var topBarSelector = "Header-module--topBarSelector--+66Gx";
export var topNavBar = "Header-module--topNavBar--8ZokL";
export var transparent = "Header-module--transparent--yyBES";
export var warning = "#ffeda0";
export var white = "#fff";
export var whiteLogoContainer = "Header-module--whiteLogoContainer--tTIfc";
export var yellow = "#fbc900";