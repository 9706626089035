import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { ALL_LANGS, SITE_BASE_URL } from '../constants';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { localize } from '../utils/sanity';
import SEO from './SEO';

interface LocalizedQueryData {
  sanityWebsite: {
    openGraph: LocalizedSEO;
  };
}

interface PageSEOProps {
  defaultTitle?: string;
  defaultDescription?: string;
  defaultImageUrl?: string;
  pageSEO?: {
    title?: string;
    description?: string;
    image?: {
      asset: {
        url: string;
      };
    };
  };
}

function PageSEO({
  defaultTitle,
  defaultDescription,
  defaultImageUrl,
  pageSEO,
}: PageSEOProps): React.ReactElement {
  const websiteSeoData = useStaticQuery(graphql`
    {
      sanityWebsite {
        openGraph {
          ...SEOWebsite
        }
      }
    }
  `);
  const location = useLocation();
  const { i18n } = useTranslation();
  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const {
    sanityWebsite: { openGraph: websiteSEO },
  } = localize(websiteSeoData, langs) as LocalizedQueryData;
  return (
    <SEO
      title={
        pageSEO?.title || defaultTitle
          ? (pageSEO?.title || defaultTitle) + ' - ' + websiteSEO?.title
          : websiteSEO?.title
      }
      description={pageSEO?.description || defaultDescription || websiteSEO?.description}
      url={`${SITE_BASE_URL}${location.pathname}`}
      imageUrl={pageSEO?.image?.asset.url || defaultImageUrl || websiteSEO?.image?.asset.url}
      lang={i18n.language}
    />
  );
}

export default PageSEO;
