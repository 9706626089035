// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var button = "CookieBar-module--button--+Wctr";
export var buttonsWrapper = "CookieBar-module--buttonsWrapper--iyk2i";
export var container = "CookieBar-module--container--BPwSE";
export var content = "CookieBar-module--content--3C8Dp";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var declineButton = "CookieBar-module--declineButton--7IBj4";
export var disabled = "CookieBar-module--disabled--3fNgG";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var hidden = "CookieBar-module--hidden--kk--7";
export var innerContainer = "CookieBar-module--innerContainer--vi37j";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";