import React, { useContext, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { MenuItem as ReactMenuItem, ControlledMenu, MenuState } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import * as styles from './MenuEntry.module.scss';
import { SingleEntry, SubmenuEntry } from './Menu';
import { getMenuLinkClasses, slugify } from '../utils/utils';
import { LocationContext } from '../contexts/LocationContext';
import LocalizedLink from './LocalizedLink';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { getLocalizedUrlPath } from '../utils/i18n';
import { useTranslation } from 'react-i18next';
import arrow from '../assets/arrow.svg';
import { FaChevronDown } from 'react-icons/fa';

const SUBMENU_OFFSET_Y = 6;

interface MenuProps {
  entry: SingleEntry | SubmenuEntry;
  hasLightLinks?: boolean;
  sectionsMenuProps?: {
    sectionsMenuEntriesRef: React.MutableRefObject<HTMLDivElement | null>;
    showSectionsMenu: boolean;
    sectionsMenuEntriesRightOffset: number;
  };
}

const MenuEntry = ({ entry, hasLightLinks, sectionsMenuProps }: MenuProps): React.ReactElement => {
  const { sectionsMenuEntriesRef, showSectionsMenu, sectionsMenuEntriesRightOffset } =
    sectionsMenuProps || {};
  const { i18n } = useTranslation();

  const location = useContext(LocationContext);

  const ref = useRef(null);
  const [isEntryHovered, setIsEntryHovered] = useState(false);
  const [isOffsetSpaceHovered, setIsOffsetSpaceHovered] = useState(false);
  const [isSubMenuHovered, setIsSubMenuHovered] = useState(false);

  return 'entries' in entry ? (
    <>
      <div
        className={
          hasLightLinks
            ? styles.lightSubmenuEntry
            : styles.submenuEntry +
              (location &&
              entry.entries.some(subEntry => location.pathname.startsWith(subEntry.url))
                ? ' ' + styles.selected
                : '')
        }
      >
        <li
          className={
            styles.linkWrapper +
            (location &&
              entry.entries.some(subEntry =>
                getMenuLinkClasses(
                  location.pathname,
                  subEntry.url,
                  styles.selected,
                  styles.disabled,
                ),
              )) +
            (isSubMenuHovered ? ' ' + styles.hovered : '')
          }
          ref={ref}
          onMouseEnter={() => {
            setIsEntryHovered(true);
          }}
          onMouseLeave={() => {
            setIsEntryHovered(false);
          }}
        >
          <span className={styles.submenuTitle}>
            {entry.title}
            <FaChevronDown className={hasLightLinks ? styles.lightIcon : styles.submenuIcon} />
          </span>
        </li>

        <div
          style={{ height: SUBMENU_OFFSET_Y + 'px' }}
          onMouseEnter={() => {
            setIsOffsetSpaceHovered(true);
          }}
          onMouseLeave={() => {
            setIsOffsetSpaceHovered(false);
          }}
        ></div>
        <ControlledMenu
          offsetY={SUBMENU_OFFSET_Y}
          menuClassName={hasLightLinks ? styles.darkSubmenu : styles.subMenu}
          state={isEntryHovered || isOffsetSpaceHovered || isSubMenuHovered ? 'open' : 'closed'}
          anchorRef={ref}
          onMouseEnter={() => {
            setIsSubMenuHovered(true);
          }}
          onMouseLeave={() => {
            setIsSubMenuHovered(false);
          }}
        >
          {entry.entries.map((subEntry, i) => (
            <Link
              key={i}
              to={subEntry.url}
              className={
                styles.subMenuLink +
                (location &&
                  getMenuLinkClasses(
                    location.pathname,
                    subEntry.url,
                    styles.selected,
                    styles.disabled,
                  ))
              }
            >
              <ReactMenuItem>{subEntry.title}</ReactMenuItem>
              <div className={styles.submenuLinkSpacer}></div>
            </Link>
          ))}
        </ControlledMenu>
      </div>
    </>
  ) : (
    <li className={styles.linkWrapper}>
      <LocalizedLink
        to={entry.url}
        className={
          styles.link +
          (location
            ? getMenuLinkClasses(location.pathname, entry.url, styles.selected, styles.disabled)
            : '') +
          (hasLightLinks ? ' ' + styles.lightLink : '')
        }
      >
        {entry.title}
      </LocalizedLink>
      {entry.sections &&
        !entry.hideSectionsMenu &&
        sectionsMenuProps &&
        getLocalizedUrlPath(entry.url, i18n.language) ===
          (location?.pathname?.replace(/\/$/, '') || null) && (
          <ul
            className={styles.sectionsMenuContainer}
            style={{
              visibility: showSectionsMenu ? 'visible' : 'hidden',
            }}
          >
            <img src={arrow} alt="" className={styles.arrow} />
            <div
              ref={sectionsMenuEntriesRef}
              className={styles.sectionsMenuEntriesWrapper}
              style={{
                right: sectionsMenuEntriesRightOffset,
              }}
            >
              {entry.sections.map((section, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <li className={styles.linkSpacer}></li>}
                  <li key={index} className={styles.linkWrapper}>
                    <AnchorLink
                      href={'#' + slugify(section.nameEn)}
                      className={styles.link + (hasLightLinks ? ' ' + styles.lightLink : '')}
                    >
                      {section.name}
                    </AnchorLink>
                  </li>
                </React.Fragment>
              ))}
            </div>
          </ul>
        )}
    </li>
  );
};

export default MenuEntry;
