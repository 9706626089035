import React, { useLayoutEffect, useRef, useState } from 'react';
import MenuSpy from 'menuspy';

import * as styles from './Menu.module.scss';
import * as menuEntryStyles from './MenuEntry.module.scss';
import MenuEntry from './MenuEntry';

export interface SingleEntry {
  url: string;
  title: string;
  sections?: Array<{
    name: string;
    nameEn: string;
  }>;
  hideSectionsMenu?: boolean | null;
}

export interface SubmenuEntry {
  title: string;
  entries: Array<SingleEntry>;
}

interface MenuProps {
  narrow?: boolean;
  pageContent?: boolean;
  hasLightLinks?: boolean;
  entries: Array<SingleEntry | SubmenuEntry>;
}

const Menu = ({ narrow, pageContent, hasLightLinks, entries }: MenuProps): React.ReactElement => {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const sectionsMenuEntriesRef = useRef<HTMLDivElement | null>(null);
  const [showSectionsMenu, setShowSectionsMenu] = useState<boolean>(false);
  const [sectionsMenuEntriesRightOffset, setSectionsMenuEntriesRightOffset] = useState<number>(0);

  const menuSpyRef = useRef<any>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (containerRef.current && sectionsMenuEntriesRef.current) {
        const { left: containerLeft, right: containerRight } =
          containerRef.current.getBoundingClientRect();
        const { left: sectionsMenuEntriesLeft, right: sectionsMenuEntriesRight } =
          sectionsMenuEntriesRef.current.getBoundingClientRect();
        if (sectionsMenuEntriesRight > containerRight) {
          setSectionsMenuEntriesRightOffset(sectionsMenuEntriesRight - containerRight);
        } else if (sectionsMenuEntriesLeft < containerLeft) {
          setSectionsMenuEntriesRightOffset(sectionsMenuEntriesLeft - containerLeft);
        }
        setShowSectionsMenu(true);
        menuSpyRef.current = new MenuSpy(
          document.querySelector(`.${menuEntryStyles.sectionsMenuEntriesWrapper}`),
          {
            menuItemSelector: `.${menuEntryStyles.link}`,
            activeClass: menuEntryStyles.selected,
            threshold: 300,
          },
        );
        // HACK: Force menuSpy to run at least once, so that the first entry gets selected
        menuSpyRef.current.tick();
      }
      // HACK: Delay calculating section menu position and showing it
      //  to give time for layout to settle
    }, 200);
    return () => {
      if (menuSpyRef.current) {
        menuSpyRef.current.destroy();
      }
    };
  }, []);

  return (
    <nav
      className={
        styles.menuDesktop +
        (narrow ? ' ' + styles.narrow : '') +
        (pageContent ? ' ' + styles.pageContent : '')
      }
    >
      <ul ref={containerRef} className={styles.container}>
        {entries.map((entry, i) => (
          <React.Fragment key={i}>
            {i > 0 && <li className={styles.linkSpacer}></li>}
            <MenuEntry
              entry={entry}
              hasLightLinks={hasLightLinks}
              sectionsMenuProps={{
                sectionsMenuEntriesRef,
                showSectionsMenu,
                sectionsMenuEntriesRightOffset,
              }}
            ></MenuEntry>
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
