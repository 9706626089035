import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Menu from './Menu';
import DropDownMenu from './DropDownMenu';
import MenuButton from './MenuButton';

import * as styles from './Header.module.scss';

import logoSvg from '../assets/images/logos/ASCENZA_logo.svg';
import whiteLogoSvg from '../assets/images/logos/ASCENZA_logo_white_rovensa-company.svg';
import CountriesWebsitesDropDown from './CountriesWebsitesDropDown';
import { useLocalization, useScrollY } from '../utils/hooks';
import LocalizedLink from './LocalizedLink';
import LanguageSelector from './LanguageSelector';
import { MenuItem, SubmenuItem } from '../graphql-fragments/Menu';
import { LANGUAGE_SELECTOR_ENABLED } from '../constants';
import { clsx } from '../utils/utils';

interface HeaderProps {
  isMenuOpen: boolean;
  onHamburguerMenuTouch: () => void;
  transparent?: boolean;
}

interface LocalizedQueryData {
  sanityWebsite: {
    title: string;
    headerNavigationMenuReference: {
      title: string;
      items: Array<MenuItem | SubmenuItem>;
    };
  };
}

const Header = ({
  isMenuOpen,
  onHamburguerMenuTouch,
  transparent,
}: HeaderProps): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          headerNavigationMenuReference {
            items {
              ...Menu
            }
          }
        }
      }
    `,
  );

  function getMenuItemWithNameEn(menuItem) {
    return {
      ...menuItem,
      page: {
        ...menuItem.page,
        sections: menuItem.page.sections.map(section => ({
          ...section,
          nameEn: section.name.en,
        })),
      },
    };
  }

  data.sanityWebsite.headerNavigationMenuReference.items =
    data.sanityWebsite.headerNavigationMenuReference.items.map(item =>
      item.__typename === 'SanitySubmenuItem'
        ? {
            ...item,
            entries: item.entries.map(getMenuItemWithNameEn),
          }
        : getMenuItemWithNameEn(item),
    );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);
  const headerMenu = localizedData.sanityWebsite.headerNavigationMenuReference || {};

  const entries = headerMenu.items.map(item =>
    item.entries
      ? {
          ...item,
          entries: item.entries.map(subEntry => ({
            url: '/' + subEntry.page.slug.current,
            title: subEntry.title || subEntry.page.title,
            sections: subEntry.page.sections,
            hideSectionsMenu: subEntry.page.hideSectionsMenu,
          })),
        }
      : {
          url: '/' + item.page.slug.current,
          title: item.title || item.page.title,
          sections: item.page.sections,
          hideSectionsMenu: item.page.hideSectionsMenu,
        },
  );

  const scrollY = useScrollY();
  const pageScrolled = !!scrollY && scrollY > 0;

  return (
    <header
      className={styles.header + (pageScrolled ? ' ' + styles.onScrolledPage : '') + ' mui-fixed'}
    >
      <div className={styles.topNavBar}>
        <div className={styles.leftContainer}>
          <CountriesWebsitesDropDown></CountriesWebsitesDropDown>
          <a
            href="https://www.rovensa.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.externalLink}
          >
            {t('header.rovensa_link', 'ROVENSA Group')}
          </a>
        </div>

        {LANGUAGE_SELECTOR_ENABLED && (
          <LanguageSelector className={styles.topBarSelector}></LanguageSelector>
        )}
      </div>

      <section
        className={styles.navBar + (transparent && !pageScrolled ? ' ' + styles.transparent : '')}
      >
        <div className={styles.container}>
          <div
            className={clsx(
              styles.logosWrapper,
              !LANGUAGE_SELECTOR_ENABLED && styles.logosWrapperWithoutLanguageSelector,
            )}
          >
            <LocalizedLink to="/" className={styles.whiteLogoContainer}>
              <img className={styles.logo} src={whiteLogoSvg} alt="Ascenza Logo" />
            </LocalizedLink>
            <LocalizedLink to="/" className={styles.logoContainer}>
              <img className={styles.logo} src={logoSvg} alt="Ascenza Logo" />
            </LocalizedLink>
          </div>
          {LANGUAGE_SELECTOR_ENABLED && (
            <LanguageSelector className={styles.headerSelector}></LanguageSelector>
          )}

          <LocalizedLink to="/" className={styles.tabletLogoContainer}>
            <img className={styles.logo} src={logoSvg} alt="Ascenza Logo" />
          </LocalizedLink>
          <div className={styles.menuWrapper}>
            <Menu hasLightLinks={transparent && !pageScrolled} entries={entries}></Menu>
          </div>
          <div className={styles.hamburgerMenu}>
            <MenuButton
              label={t('header.menu_label', 'Menu')}
              isMenuOpen={isMenuOpen}
              onTouch={onHamburguerMenuTouch}
            ></MenuButton>
          </div>
        </div>
      </section>
      {(!transparent || pageScrolled) && <div className={styles.shadow}></div>}
      <div className={styles.dropDownMenuWrapperWindow}>
        <div className={styles.dropDownMenuWrapper + (isMenuOpen ? ' ' + styles.menuOpen : '')}>
          <DropDownMenu entries={entries}></DropDownMenu>
        </div>
      </div>
    </header>
  );
};

export default Header;
