// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var container = "Footer-module--container--0c9rU";
export var coolGrey = "#e1e2e0";
export var copyright = "Footer-module--copyright--v7mXx";
export var countryAndLogoSection = "Footer-module--countryAndLogoSection--5CQZ-";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var error = "#f66a83";
export var footer = "Footer-module--footer--rLNBC";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "Footer-module--icon--wXDpC";
export var leftSide = "Footer-module--leftSide--i6RHb";
export var legalDocumentation = "Footer-module--legalDocumentation--1rbA1";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "Footer-module--link--rm15h";
export var logo = "Footer-module--logo--WSN3h";
export var logoContainer = "Footer-module--logoContainer--qNVjx";
export var logoFooter = "Footer-module--logoFooter--683jE";
export var menu = "Footer-module--menu--GBsYG";
export var moscaLink = "Footer-module--moscaLink--pou2F";
export var moscaSection = "Footer-module--moscaSection--CspRu";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var partnerImageLink = "Footer-module--partnerImageLink--3ooJn";
export var partners = "Footer-module--partners--5ihIw";
export var rightSide = "Footer-module--rightSide--KZLXa";
export var section = "Footer-module--section--47hVn";
export var socialMediaLink = "Footer-module--socialMediaLink--hO5kZ";
export var socialMediaSection = "Footer-module--socialMediaSection--Zje7W";
export var subtitle = "Footer-module--subtitle--MmtxY";
export var success = "#1ebc7b";
export var title = "Footer-module--title--FaIhp";
export var topSection = "Footer-module--topSection--DOyMA";
export var warning = "#ffeda0";
export var white = "#fff";
export var worldwide = "Footer-module--worldwide--81fFb";
export var yellow = "#fbc900";